import ImageModal from './ImageModal';
import { GridContainer, Heading, StyledChip } from './style';

export interface RenderCardValue {
  id: string;
  name: string;
  isDisable?: boolean;
  secondName?: string;
  line2?: string;
}

export const RenderChipComponent = ({
  heading,
  keyName,
  floors,
  values,
  columnNumber = 3,
  handleSelect,
  selectedValues,
  floorNotFound,
  isSelctedValue,
}: {
  values?: RenderCardValue[];
  keyName: string;
  floors?: { floorNumber: number; floorMap?: string; floorMapThumbnail?: string }[];
  heading: string;
  handleSelect: (key: string, value: string) => void;
  columnNumber?: number;
  floorNotFound?: boolean;
  isSelctedValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedValues?: any;
}) => {
  const isLocationKey = [
    'location',
    'datePref',
    'startTimeTemp',
    'startTime',
    'endTimeTemp',
    'endTime',
    'capacity',
  ].includes(keyName);

  // 'startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'
  const getSize = () => {
    if (keyName === 'capacity') return 's';

    if (['startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'].includes(keyName)) return 'm';

    return undefined;
  };

  const handleChipClick = (id: string) => handleSelect(keyName, id);

  return (
    <GridContainer $columnNumber={columnNumber}>
      <Heading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          userSelect: 'none',
          textTransform: 'uppercase',
        }}
      >
        {heading}
      </Heading>

      {values?.map(({ id, name, isDisable, secondName, line2 }) => {
        const isSelected = selectedValues?.[keyName] === id || id === isSelctedValue;

        return (
          <StyledChip
            key={id}
            onClick={() => handleChipClick(id)}
            $isDisable={isDisable}
            $isSelected={isSelected}
            $isLocation={isLocationKey}
            $size={getSize()}
            style={{
              flexDirection: line2 ? 'column' : 'row',
              flexWrap: 'wrap',
              ...(['startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'].includes(keyName) ? { padding: 0 } : {}),
            }}
          >
            {name}
            {secondName && <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{secondName}</span>}
            {line2 && <div>{line2}</div>}
          </StyledChip>
        );
      })}

      {floors?.map(({ floorNumber, floorMap, floorMapThumbnail }) => {
        const isSelected = selectedValues?.[keyName] === floorNumber.toString();

        return (
          <div key={`${floorNumber}-floors`}>
            <ImageModal
              imageUrl={floorMap || ''}
              thumbnailUrl={floorNumber === 0 ? '/images/1000289544.png' : floorMapThumbnail}
              text={floorNumber === 0 ? 'Any Floor' : `${floorNumber} Floor`}
              handleTextClick={() => handleSelect(keyName, floorNumber.toString())}
              isSelected={isSelected}
            />
          </div>
        );
      })}

      {floorNotFound && keyName === 'floorNumber' ? (
        <StyledChip style={{ gridColumn: '1/-1' }}>No Floor Found</StyledChip>
      ) : null}
    </GridContainer>
  );
};
