import React, { useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, useMediaQuery } from '@mui/material';
// import { Box, IconButton } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { ImageCarouselDialog } from '@src/components/Modal/ImageCarouselDialog';
import { RoomsType } from '@src/Modals/Rooms';

import { StyledChip } from './style';

interface RoomCardProps {
  room: RoomsType;
  isSelected: boolean;
  onClick: () => void;
}

export const RoomCard: React.FC<RoomCardProps> = ({ room, isSelected, onClick }) => {
  const { roomNumber, _id, pictures, roomDescription, capacity } = room;
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const [open, setOpen] = useState(false);

  const handleImageClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
    // setOpen(true);
  };

  return (
    <StyledChip key={_id} onClick={onClick} $fullHeight style={{ borderRadius: '10px' }} $isSelected={isSelected}>
      <Typography
        gutterBottom
        variant='h5'
        component='div'
        style={{
          background: '#05b6d8',
          color: 'white',
          width: '100%',
          height: 'fit-content',
        }}
      >
        {`ROOM ${roomNumber}`}
      </Typography>
      <Box
        position='relative'
        {...(isDesktop && pictures[0]?.url ? {} : { height: '120px' })}
        width='100%'
        sx={{
          flex: 1,
          overflow: 'hidden',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          '&:hover .overlay': { opacity: 1 },
        }}
      >
        {pictures[0]?.url ? (
          <CardMedia
            component='img'
            alt='room image'
            height={'120'}
            image={pictures[0]?.url}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            onClick={handleImageClick}
          />
        ) : (
          <Box height='100%' display='flex' alignItems='center' justifyContent='center' width='100%'>
            <img src='/images/noImage.svg' alt='noImage' height={50} />
          </Box>
        )}
        {/* <Box
          className='overlay'
          position='absolute'
          top='0'
          left='0'
          height='100%'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            opacity: 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          <IconButton color='inherit' onClick={handleImageClick}>
            <ZoomInIcon fontSize='large' />
          </IconButton>
        </Box> */}
      </Box>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '10px' }}>
        {capacity === '1 person' ? (
          <PersonIcon sx={{ height: '16px', width: 'auto', color: 'black' }} />
        ) : (
          <img src='/images/1000273368.png' alt='' height={14} />
        )}
        {capacity.includes('+') ? capacity : capacity.split(' ').join('-')}
      </div>
      <Typography
        variant='body2'
        sx={{
          marginTop: '3px',
          textAlign: 'left',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
        }}
      >
        {roomDescription}
      </Typography>
      {pictures?.length ? <ImageCarouselDialog images={pictures} open={open} onClose={() => setOpen(false)} /> : null}
    </StyledChip>
  );
};
