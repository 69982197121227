import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Divider } from '@mui/material';

import { sendFeedback } from '@src/api/rooms';
import { UiRoutes } from '@src/lib/constants';
import { Header } from '@src/screens/Booking/style';
import { StyledMainContainer } from '@src/screens/Home/style';

import FeedbackDialog from '../Modal/FeedbackModal';

export const PageHeader = ({ onClick }: { onClick?: () => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const isRessy = location.pathname.indexOf('demo');

  const handleNavigation = () => {
    window.location.href = `/${window.location.pathname.split('/')[1]}`;
  };

  const handleMyBooking = () => {
    navigate(UiRoutes.MY_BOOKING);
  };

  return (
    <>
      <StyledMainContainer style={{ position: 'sticky', top: 0, background: 'white', zIndex: '100', padding: '0 0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Header style={{ background: 'transparent' }}>
            <img
              src={isRessy > -1 ? '/images/Reasy_RGB_full_color.jpg' : '/images/1000276066.png'}
              style={isRessy > -1 ? { width: 'auto', height: '40px' } : {}}
              onClick={onClick ? onClick : handleNavigation}
              alt='logo'
            />
          </Header>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={handleMyBooking}
              variant='outlined'
              style={{
                fontWeight: '700',
                color: 'black',
                padding: '6px 8px',
                textTransform: 'none',
                borderRadius: '5px',
                backgroundColor: 'yellow',
                fontSize: '14px',
              }}
            >
              My Bookings
            </Button>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              variant='outlined'
              style={{
                fontWeight: '700',
                color: 'black',
                padding: '6px 8px',
                textTransform: 'none',
                borderRadius: '5px',
                backgroundColor: 'yellow',
                fontSize: '14px',
              }}
            >
              Feedback
            </Button>
          </div>
        </div>
        <FeedbackDialog
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleSubmit={async feedback => {
            try {
              setIsOpen(false);
              await sendFeedback(feedback);
            } catch (err) {
              console.log('err', err);
            }
          }}
        />
      </StyledMainContainer>
      <Divider />
    </>
  );
};
