/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useMediaQuery } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { dateOptions } from '@src/lib/constants';
import { getNextSixMondays } from '@src/lib/helper';

import { GridContainer, StyledChip } from './style';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const Calendar = ({
  handleSelect,
  selectedDate,
  selectedDatePref,
  dateRange,
  setDateRange,
  setSelectedDatePref,
}: {
  handleSelect: (key: string, value: string) => void;
  selectedDate: string;
  setSelectedDatePref: Dispatch<SetStateAction<string>>;
  selectedDatePref: string;
  setDateRange: Dispatch<SetStateAction<Range[]>>;
  dateRange: Range[];
}) => {
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedNewDate, setSelectedDate] = useState<any>(null);
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const isMultipleDays = selectedDatePref === 'multipleDays';

  useEffect(() => {
    if (selectedDatePref === 'singleRecurringDays') {
      return;
    }

    if (selectedNewDate && selectedDatePref && !isMultipleDays) {
      // Handle the case for single day selection
      handleSelect('selectedDate', selectedNewDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatePref, selectedNewDate, dateRange, isMultipleDays]);

  return (
    <div>
      <div>
        {isMultipleDays ? (
          <StyledDateRange
            dateDisplayFormat='eee do'
            ranges={dateRange}
            $isDesktop={isDesktop}
            onChange={newValue => {
              setDateRange([newValue.selection]);
              setSelectedDate(newValue.selection?.startDate);
            }}
            className='custom-style-range'
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              views={['day']}
              value={selectedNewDate ? dayjs(selectedNewDate) : selectedDate ? dayjs(selectedDate) : null}
              sx={{
                '&.MuiDateCalendar-root': {
                  margin: '0',
                  height: 'auto',
                  maxHeight: 'none',
                  width: '100%',
                  '.MuiDayCalendar-root': {
                    '.MuiDayCalendar-weekDayLabel': {
                      flex: 1,
                      fontWeight: '700',
                      fontSize: isDesktop ? '18px' : '15px',
                    },
                    '.MuiPickersDay-root': {
                      flex: 1,
                      fontSize: isDesktop ? '18px' : '15px',
                      borderRadius: '0px',
                      height: '40px',
                      fontWeight: '700',
                    },
                  },
                  '.MuiPickersCalendarHeader-root': {
                    '.MuiPickersCalendarHeader-label': {
                      fontWeight: '700',
                      fontSize: isDesktop ? '18px' : '15px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    },
                    padding: '20px 12px 20px 24px',
                    background: 'purple',
                    color: 'white',
                    '.MuiSvgIcon-root': {
                      color: 'white',
                    },
                  },
                  '.MuiPickersCalendarHeader-switchViewButton': {
                    display: 'none',
                  },
                  '.Mui-selected': {
                    background: 'green',
                  },
                },
              }}
              onChange={newValue => {
                setSelectedDate(newValue);

                if (selectedDatePref === 'singleRecurringDays') {
                  return;
                }

                if (selectedDatePref) {
                  handleSelect('selectedDate', newValue || '');
                }
              }}
            />
          </LocalizationProvider>
        )}
      </div>

      <GridContainer $columnNumber={2}>
        {isMultipleDays && selectedNewDate ? (
          <StyledChip
            $isSelected
            onClick={() => {
              if (dateRange[0].startDate && dateRange[0].endDate) {
                if (dateRange[0].startDate.toString() !== dateRange[0].endDate.toString()) {
                  handleSelect('selectedDate', dateRange[0].startDate.toString());
                }
              }
            }}
          >
            SELECT THESE DAYS
          </StyledChip>
        ) : null}

        {selectedDatePref === 'singleRecurringDays' ? (
          <>
            <div style={{ gridColumn: '1/-1', display: 'grid', gridTemplate: '1fr 1fr' }}>
              <StyledChip
                $isSelected
                $isDisable={!selectedNewDate}
                onClick={() => {
                  if (selectedNewDate) {
                    handleSelect('selectedDate', selectedNewDate);
                  }
                }}
              >
                SELECT THESE DAYS
              </StyledChip>
            </div>
            {selectedDate || selectedNewDate
              ? getNextSixMondays(`${selectedDate || selectedNewDate}`)?.map(dayDate => {
                  return (
                    <StyledChip
                      $isLocation
                      onClick={() => {
                        if (selectedDates.includes(dayDate)) {
                          setSelectedDates(prev => prev.filter(data => data !== dayDate));
                        } else {
                          setSelectedDates(prev => [...prev, dayDate]);
                        }
                      }}
                      $isSelected={selectedDates.includes(dayDate)}
                    >
                      {dayDate}
                    </StyledChip>
                  );
                })
              : null}
          </>
        ) : null}

        {!(isMultipleDays || selectedDatePref === 'singleRecurringDays')
          ? dateOptions?.map(({ id, name, isDisable, secondName }) => (
              <StyledChip
                key={id}
                $isPadding
                onClick={() => {
                  if (!isDisable) {
                    setSelectedDatePref(id);

                    if (selectedNewDate && selectedDatePref && id !== 'multipleDays') {
                      handleSelect('selectedDate', selectedNewDate);
                    }
                  }
                }}
                $isDisable={isDisable}
                $isSelected={selectedDatePref === id}
                $isLocation
                style={{
                  flexDirection: isDesktop ? 'row' : 'column',
                  flexWrap: 'wrap',
                  paddingTop: '0',
                  paddingBottom: '0',
                }}
              >
                {name}
                {secondName ? <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{secondName}</span> : null}
              </StyledChip>
            ))
          : null}
      </GridContainer>
    </div>
  );
};

const StyledDateRange = styled(DateRange)<{ $isDesktop: boolean }>(({ $isDesktop }) => ({
  width: '100%',
  position: 'relative',
  marginBottom: '70px',
  '.rdrDateDisplayWrapper': {
    position: 'absolute',
    width: '100%',
    bottom: '-60px',
    input: {
      fontWeight: '700',
      color: 'black',
      fontSize: $isDesktop ? '20px' : '15px',
    },
  },
  '.rdrMonthAndYearWrapper': {
    '.rdrMonthAndYearPickers': {
      select: {
        fontWeight: '700',
        fontSize: $isDesktop ? '18px' : '15px',
      },
    },
  },
  '.rdrMonths': {
    width: '100%',
    '.rdrMonth': {
      padding: '0',
      paddingBottom: '24px',
      width: '100%',
      '.rdrWeekDays': {
        '.rdrWeekDay': {
          fontWeight: '700',
          fontSize: $isDesktop ? '18px' : '15px',
        },
      },
      '.rdrDays': {
        '.rdrDay': {
          '.rdrDayNumber': {
            span: {
              fontWeight: '600',
              fontSize: $isDesktop ? '17px' : '14px',
            },
          },
        },
      },
    },
  },
}));
