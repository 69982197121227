import { Organisation } from '@src/hooks/useVerifyOrgination';
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const getOrganisationByOrg = async ({ orgID }: { orgID: string }): Promise<Organisation> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ORGANISATION}/by/${orgID}`,
  });
};
