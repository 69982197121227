import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Autocomplete, Chip, TextField, useMediaQuery } from '@mui/material';

import { callMsGraph } from '@src/api/graph';
import { loginRequest } from '@src/config/authConfig';
import { usersListRes } from '@src/lib/users';

import { StyledChip } from './style';

export const ContactsList = ({ endTime, startTime }: { startTime: string; endTime: string }) => {
  const [selectedUsers, setSelectedUsers] = useState<{ value: string; label: string }[]>([]);
  const [selectUser, setSelectUser] = useState(false);
  const [multiPersonBooking, setMultiPersonBooking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphUsersData, setGraphUsersData] = useState<any>(null);

  const start = dayjs(startTime, 'h:mm');
  const end = dayjs(endTime, 'h:mm');
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const oneHourBeforeStart = start.subtract(1, 'hour').format('h:mm');
  const oneHourBeforeAfterStart = dayjs(oneHourBeforeStart, 'h:mm').subtract(1, 'hour').format('h:mm');
  const oneHourAfterEnd = end.add(1, 'hour').format('h:mm');

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error('Login failed:', e);
    });
  };

  const fetchUserData = async () => {
    if (accounts.length > 0) {
      setLoading(true);

      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // Fetch users from Graph API
        const userData = await callMsGraph(response.accessToken);
        localStorage.setItem('graphAccessToken', response.accessToken);
        setGraphUsersData(userData);
        console.log('>>>graphUsersData', graphUsersData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle logout via MSAL
  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error('Logout failed:', e);
    });
  };

  const handleCheckboxChange = () => {
    setMultiPersonBooking(!multiPersonBooking);

    if (multiPersonBooking || isAuthenticated) {
      setSelectUser(prev => !prev);
    }

    if (!multiPersonBooking && !isAuthenticated) {
      handleLogin();
    }
  };

  useEffect(() => {
    if (isAuthenticated && multiPersonBooking) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, multiPersonBooking]);

  return (
    <div style={{ gridColumn: '1/-1' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px', marginBottom: '16px' }}>
        <StyledChip
          $isLocation
          onClick={handleCheckboxChange}
          style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: '0', paddingBottom: '0' }}
          $isPadding
        >
          Add Attendee
        </StyledChip>
      </div>
      {selectUser ? (
        <Autocomplete
          sx={{
            '& .MuiOutlinedInput-root': {
              margin: '16px 0  ',
              '& fieldset': {
                borderColor: '#05b6d8',
                borderWidth: 2,
              },
              '&:hover fieldset': {
                borderColor: '#05b6d8',
                borderWidth: 2,
              },
              '&.Mui-focused fieldset': {
                borderColor: '#05b6d8',
                borderWidth: 2,
              },
            },
          }}
          onChange={(_, newValue) => {
            if (newValue) {
              setSelectedUsers(prev => {
                if (prev?.some(({ value }) => value === newValue.value)) {
                  return prev;
                }

                return [...(prev || []), newValue];
              });
              setSelectUser(false);
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.label}
                sx={{
                  backgroundColor: '#05b6d8',
                  borderRadius: '5px',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#039abc',
                  },
                  '.MuiChip-deleteIcon': {
                    fill: '#fff',
                  },
                }}
              />
            ))
          }
          options={usersListRes?.value?.map(({ displayName, id }) => ({ label: displayName, value: id })) || []}
          disablePortal
          renderInput={params => <TextField {...params} placeholder='Users List' />}
        />
      ) : null}

      {/* User List */}
      {selectedUsers?.length ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
          <div style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: '600', textAlign: 'start' }}>
            {oneHourBeforeStart}
          </div>
          <div style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: '600', textAlign: 'start' }}>
            {start.format('h:mm')}
          </div>
          <div style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: '600', textAlign: 'start' }}>
            {oneHourBeforeAfterStart}
          </div>
          <div style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: '600', textAlign: 'start' }}>
            {end.format('h:mm')}
          </div>
          <div style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: '600', textAlign: 'start' }}>
            {oneHourAfterEnd}
          </div>
        </div>
      ) : null}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '16px 0' }}>
        {selectedUsers?.map(({ label, value }, index) => (
          <div key={index} style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
            <div
              style={{
                background: 'linear-gradient(90deg, #ff4b4b, #ff7575)',
                color: 'black',
                fontSize: '13px',
                fontWeight: 600,
                borderRadius: '10px 0 0 10px',
                position: 'relative',
              }}
            >
              <span style={{ position: 'absolute', left: '10px', whiteSpace: 'nowrap', top: '2px' }}>{label}</span>
            </div>
            <div
              style={{
                background: 'linear-gradient(90deg, #78c800, #b4f000)',
                color: 'transparent',
              }}
            >
              .
            </div>
            <div
              style={{
                background: 'linear-gradient(90deg, #78c800, #b4f000)',
                color: 'transparent',
              }}
            >
              .
            </div>
            <div
              style={{
                background: 'linear-gradient(90deg, #ff4b4b, #ff7575)',
                color: 'transparent',
              }}
            >
              .
            </div>
            <div
              style={{
                background: 'linear-gradient(90deg, #78c800, #b4f000)',
                color: 'transparent',
                borderRadius: '0 10px 10px 0',
              }}
            >
              .
            </div>
          </div>
        ))}
      </div>
      {isAuthenticated && multiPersonBooking && (
        <div>
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>
          ) : null}
        </div>
      )}
    </div>
  );
};
