import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Dialog, DialogContent, IconButton, Typography, useMediaQuery } from '@mui/material';

import { StyledChip } from './style';

interface ImageModalProps {
  imageUrl: string;
  text: string;
  handleTextClick: () => void;
  isSelected: boolean;
  thumbnailUrl?: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, text, handleTextClick, isSelected, thumbnailUrl }) => {
  const [scale, setScale] = useState(1);
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const handleZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    setScale(prevScale => prevScale + 0.1);
  };

  const handleZoomOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    setScale(prevScale => (prevScale > 0.1 ? prevScale - 0.1 : 0.1));
  };

  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
    setScale(1);
  };

  return (
    <StyledChip
      onClick={e => {
        e.stopPropagation();
        handleTextClick();
      }}
      $fullHeight
      $isSelected={isSelected}
      onMouseLeave={e => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)')}
      onMouseEnter={e => (e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)')}
      style={{
        height: '100%',
      }}
    >
      <Typography
        gutterBottom
        variant='h5'
        component='div'
        style={{
          background: isSelected ? 'white' : '#05b6d8',
          color: isSelected ? '#05b6d8' : 'white',
          width: '100%',
          height: 'fit-content',
        }}
      >
        {text}
      </Typography>
      <div
        style={{
          width: '100%',
          margin: thumbnailUrl ? (text === 'Any Floor' ? 'auto' : undefined) : 'auto',
        }}
      >
        <img
          // onClick={e => {
          //   if (imageUrl) {
          //     e.stopPropagation();
          //     setOpen(true);
          //   }
          // }}
          src={thumbnailUrl || '/images/noImage.svg'}
          alt='Thumbnail'
          style={{
            cursor: 'pointer',
            width: '100%',
            borderRadius: '10px',
            height: thumbnailUrl && isDesktop ? 'auto' : '120px',
          }}
        />
      </div>
      <Dialog open={open} maxWidth='lg' fullWidth onClose={onClose}>
        <DialogContent
          style={{ position: 'relative', textAlign: 'center', backgroundColor: '#f9f9f9', padding: '20px' }}
          onClick={e => e.stopPropagation()}
        >
          <IconButton onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon />
          </IconButton>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <IconButton onClick={handleZoomIn} style={{ margin: '0 10px', background: 'skyblue' }}>
              <ZoomInIcon sx={{ color: 'white ' }} />
            </IconButton>
            <IconButton onClick={handleZoomOut} style={{ margin: '0 10px', background: 'skyblue' }}>
              <ZoomOutIcon sx={{ color: 'white ' }} />
            </IconButton>
          </div>
          <div style={{ overflow: 'auto', maxHeight: '80vh' }}>
            <img
              src={imageUrl}
              alt='Zoomable'
              style={{
                transform: `scale(${scale})`,
                transition: 'transform 0.3s',
                width: '100%',
                cursor: 'grab',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '10px',
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </StyledChip>
  );
};

export default ImageModal;
