import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
} from '@mui/material';

const FeedbackDialog = ({
  handleClose,
  isOpen,
  handleSubmit,
}: {
  handleClose: () => void;
  isOpen: boolean;
  handleSubmit: (feedback: { positive: string; negative: string }) => Promise<void>;
}) => {
  const [positiveFeedback, setPositiveFeedback] = useState<string>('');
  const [negativeFeedback, setNegativeFeedback] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const clearFeedback = () => {
    setPositiveFeedback('');
    setNegativeFeedback('');
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      await handleSubmit({ positive: positiveFeedback, negative: negativeFeedback });
      clearFeedback(); // Clear feedback on successful submit
      handleClose(); // Close the dialog
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    clearFeedback(); // Clear feedback on dialog close
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleDialogClose} aria-labelledby='form-dialog-title' fullWidth maxWidth='sm'>
      <DialogTitle id='form-dialog-title'>Feedback</DialogTitle>
      <DialogContent>
        <DialogContentText>What did you like about this app?</DialogContentText>
        <TextareaAutosize
          aria-label='positive-feedback'
          minRows={3}
          required
          value={positiveFeedback}
          style={{
            width: '100%',
            marginBottom: '16px',
            padding: '8px',
            boxSizing: 'border-box',
            fontSize: '16px',
          }}
          onChange={e => setPositiveFeedback(e.target.value)}
        />

        <DialogContentText>What did you not like about the app?</DialogContentText>
        <TextareaAutosize
          aria-label='negative-feedback'
          minRows={3}
          value={negativeFeedback}
          style={{
            width: '100%',
            padding: '8px',
            boxSizing: 'border-box',
            fontSize: '16px',
          }}
          onChange={e => setNegativeFeedback(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleDialogClose} color='secondary'>
          Cancel
        </Button>
        <Box position='relative'>
          <Button onClick={onSubmit} color='primary' disabled={loading || !positiveFeedback}>
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
