export const usersListRes = {
  '@odata.context': 'https://graph.microsoft.com/v1.0/$metadata#users',
  value: [
    {
      businessPhones: [],
      displayName: 'Conf Room Adams',
      givenName: null,
      jobTitle: null,
      mail: 'Adams@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Adams@M365x214355.onmicrosoft.com',
      id: '6e7b768e-07e2-4810-8459-485f84f8f204',
    },
    {
      businessPhones: ['+1 425 555 0109'],
      displayName: 'Adele Vance',
      givenName: 'Adele',
      jobTitle: 'Product Marketing Manager',
      mail: 'AdeleV@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '18/2111',
      preferredLanguage: 'en-US',
      surname: 'Vance',
      userPrincipalName: 'AdeleV@M365x214355.onmicrosoft.com',
      id: '87d349ed-44d7-43e1-9a83-5f2406dee5bd',
    },
    {
      businessPhones: ['8006427676'],
      displayName: 'MOD Administrator',
      givenName: 'MOD',
      jobTitle: null,
      mail: 'admin@M365x214355.onmicrosoft.com',
      mobilePhone: '5555555555',
      officeLocation: null,
      preferredLanguage: 'en-US',
      surname: 'Administrator',
      userPrincipalName: 'admin@M365x214355.onmicrosoft.com',
      id: '5bde3e51-d13b-4db1-9948-fe4b109d11a7',
    },
    {
      businessPhones: ['+1 858 555 0110'],
      displayName: 'Alex Wilber',
      givenName: 'Alex',
      jobTitle: 'Marketing Assistant',
      mail: 'AlexW@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '131/1104',
      preferredLanguage: 'en-US',
      surname: 'Wilber',
      userPrincipalName: 'AlexW@M365x214355.onmicrosoft.com',
      id: '4782e723-f4f4-4af3-a76e-25e3bab0d896',
    },
    {
      businessPhones: ['+1 262 555 0106'],
      displayName: 'Allan Deyoung',
      givenName: 'Allan',
      jobTitle: 'Corporate Security Officer',
      mail: 'AllanD@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '24/1106',
      preferredLanguage: 'en-US',
      surname: 'Deyoung',
      userPrincipalName: 'AllanD@M365x214355.onmicrosoft.com',
      id: 'c03e6eaa-b6ab-46d7-905b-73ec7ea1f755',
    },
    {
      businessPhones: [],
      displayName: 'Conf Room Baker',
      givenName: null,
      jobTitle: null,
      mail: 'Baker@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Baker@M365x214355.onmicrosoft.com',
      id: '013b7b1b-5411-4e6e-bdc9-c4790dae1051',
    },
    {
      businessPhones: ['+1 732 555 0102'],
      displayName: 'Ben Walters',
      givenName: 'Ben',
      jobTitle: 'VP Sales',
      mail: 'BenW@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '19/3123',
      preferredLanguage: 'en-US',
      surname: 'Walters',
      userPrincipalName: 'BenW@M365x214355.onmicrosoft.com',
      id: 'f5289423-7233-4d60-831a-fe107a8551cc',
    },
    {
      businessPhones: [],
      displayName: 'Brian Johnson (TAILSPIN)',
      givenName: 'Brian',
      jobTitle: null,
      mail: 'BrianJ@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: 'Johnson',
      userPrincipalName: 'BrianJ@M365x214355.onmicrosoft.com',
      id: 'e46ba1a2-59e7-4019-b0fa-b940053e0e30',
    },
    {
      businessPhones: ['+1 858 555 0111'],
      displayName: 'Christie Cline',
      givenName: 'Christie',
      jobTitle: 'Sr. VP Sales & Marketing',
      mail: 'ChristieC@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '131/2105',
      preferredLanguage: 'en-US',
      surname: 'Cline',
      userPrincipalName: 'ChristieC@M365x214355.onmicrosoft.com',
      id: 'b66ecf79-a093-4d51-86e0-efcc4531f37a',
    },
    {
      businessPhones: [],
      displayName: 'Conf Room Crystal',
      givenName: null,
      jobTitle: null,
      mail: 'Crystal@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Crystal@M365x214355.onmicrosoft.com',
      id: '8528d6e9-dce3-45d1-85d4-d2db5f738a9f',
    },
    {
      businessPhones: ['+1 425 555 0105'],
      displayName: 'Debra Berger',
      givenName: 'Debra',
      jobTitle: 'Administrative Assistant',
      mail: 'DebraB@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '18/2107',
      preferredLanguage: 'en-US',
      surname: 'Berger',
      userPrincipalName: 'DebraB@M365x214355.onmicrosoft.com',
      id: 'd4957c9d-869e-4364-830c-d0c95be72738',
    },
    {
      businessPhones: ['+1 205 555 0108'],
      displayName: 'Diego Siciliani',
      givenName: 'Diego',
      jobTitle: 'CVP Finance',
      mail: 'DiegoS@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '14/1108',
      preferredLanguage: 'en-US',
      surname: 'Siciliani',
      userPrincipalName: 'DiegoS@M365x214355.onmicrosoft.com',
      id: '24fcbca3-c3e2-48bf-9ffc-c7f81b81483d',
    },
    {
      businessPhones: ['+81 345550115'],
      displayName: 'Emily Braun',
      givenName: 'Emily',
      jobTitle: 'Budget Analyst',
      mail: 'EmilyB@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '97/2302',
      preferredLanguage: 'en-US',
      surname: 'Braun',
      userPrincipalName: 'EmilyB@M365x214355.onmicrosoft.com',
      id: '2804bc07-1e1f-4938-9085-ce6d756a32d2',
    },
    {
      businessPhones: ['+1 205 555 0103'],
      displayName: 'Enrico Cattaneo',
      givenName: 'Enrico',
      jobTitle: 'Attorney',
      mail: 'EnricoC@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '14/1102',
      preferredLanguage: 'en-US',
      surname: 'Cattaneo',
      userPrincipalName: 'EnricoC@M365x214355.onmicrosoft.com',
      id: '16cfe710-1625-4806-9990-91b8f0afee35',
    },
    {
      businessPhones: ['+1 309 555 0104'],
      displayName: 'Grady Archie',
      givenName: 'Grady',
      jobTitle: 'CVP Legal',
      mail: 'GradyA@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '19/2109',
      preferredLanguage: 'en-US',
      surname: 'Archie',
      userPrincipalName: 'GradyA@M365x214355.onmicrosoft.com',
      id: 'df043ff1-49d5-414e-86a4-0c7f239c36cf',
    },
    {
      businessPhones: ['+1 954 555 0118'],
      displayName: 'Henrietta Mueller',
      givenName: 'Henrietta',
      jobTitle: 'Marketing Assistant',
      mail: 'HenriettaM@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '18/1106',
      preferredLanguage: 'en-US',
      surname: 'Mueller',
      userPrincipalName: 'HenriettaM@M365x214355.onmicrosoft.com',
      id: 'c8913c86-ceea-4d39-b1ea-f63a5b675166',
    },
    {
      businessPhones: [],
      displayName: 'Conf Room Hood',
      givenName: null,
      jobTitle: null,
      mail: 'Hood@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Hood@M365x214355.onmicrosoft.com',
      id: '3fec04fc-e036-42f4-8f6f-b3b02288085c',
    },
    {
      businessPhones: ['+1 309 555 0101'],
      displayName: 'Irvin Sayers',
      givenName: 'Irvin',
      jobTitle: 'Director',
      mail: 'IrvinS@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '19/2106',
      preferredLanguage: 'en-US',
      surname: 'Sayers',
      userPrincipalName: 'IrvinS@M365x214355.onmicrosoft.com',
      id: 'baafca12-9874-4765-9576-e0e5cafe491b',
    },
    {
      businessPhones: ['+1 918 555 0101'],
      displayName: 'Isaiah Langer',
      givenName: 'Isaiah',
      jobTitle: 'Web Marketing Manager',
      mail: 'IsaiahL@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '20/1101',
      preferredLanguage: 'en-US',
      surname: 'Langer',
      userPrincipalName: 'IsaiahL@M365x214355.onmicrosoft.com',
      id: 'e3d0513b-449e-4198-ba6f-bd97ae7cae85',
    },
    {
      businessPhones: ['+1 502 555 0102'],
      displayName: 'Johanna Lorenz',
      givenName: 'Johanna',
      jobTitle: 'CVP Engineering',
      mail: 'JohannaL@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '23/2102',
      preferredLanguage: 'en-US',
      surname: 'Lorenz',
      userPrincipalName: 'JohannaL@M365x214355.onmicrosoft.com',
      id: '626cbf8c-5dde-46b0-8385-9e40d64736fe',
    },
    {
      businessPhones: ['+1 980 555 0101'],
      displayName: 'Joni Sherman',
      givenName: 'Joni',
      jobTitle: 'Paralegal',
      mail: 'JoniS@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '20/1109',
      preferredLanguage: 'en-US',
      surname: 'Sherman',
      userPrincipalName: 'JoniS@M365x214355.onmicrosoft.com',
      id: '8b209ac8-08ff-4ef1-896d-3b9fde0bbf04',
    },
    {
      businessPhones: ['+1 913 555 0101'],
      displayName: 'Lee Gu',
      givenName: 'Lee',
      jobTitle: 'CVP Research & Development',
      mail: 'LeeG@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '23/3101',
      preferredLanguage: 'en-US',
      surname: 'Gu',
      userPrincipalName: 'LeeG@M365x214355.onmicrosoft.com',
      id: '074e56ea-0b50-4461-89e5-c67ae14a2c0b',
    },
    {
      businessPhones: ['+1 918 555 0107'],
      displayName: 'Lidia Holloway',
      givenName: 'Lidia',
      jobTitle: 'Product Manager',
      mail: 'LidiaH@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '20/2107',
      preferredLanguage: 'en-US',
      surname: 'Holloway',
      userPrincipalName: 'LidiaH@M365x214355.onmicrosoft.com',
      id: '2ed03dfd-01d8-4005-a9ef-fa8ee546dc6c',
    },
    {
      businessPhones: ['+1 918 555 0104'],
      displayName: 'Lynne Robbins',
      givenName: 'Lynne',
      jobTitle: 'Product Manager',
      mail: 'LynneR@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '20/1104',
      preferredLanguage: 'en-US',
      surname: 'Robbins',
      userPrincipalName: 'LynneR@M365x214355.onmicrosoft.com',
      id: 'e8a02cc7-df4d-4778-956d-784cc9506e5a',
    },
    {
      businessPhones: ['+1 412 555 0109'],
      displayName: 'Megan Bowen',
      givenName: 'Megan',
      jobTitle: 'Auditor',
      mail: 'MeganB@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '12/1110',
      preferredLanguage: 'en-US',
      surname: 'Bowen',
      userPrincipalName: 'MeganB@M365x214355.onmicrosoft.com',
      id: '48d31887-5fad-4d73-a9f5-3c356e68a038',
    },
    {
      businessPhones: ['+1 858 555 0109'],
      displayName: 'Miriam Graham',
      givenName: 'Miriam',
      jobTitle: 'VP Marketing',
      mail: 'MiriamG@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '131/2103',
      preferredLanguage: 'en-US',
      surname: 'Graham',
      userPrincipalName: 'MiriamG@M365x214355.onmicrosoft.com',
      id: '08fa38e4-cbfa-4488-94ed-c834da6539df',
    },
    {
      businessPhones: ['+1 206 555 0105'],
      displayName: 'Nestor Wilke',
      givenName: 'Nestor',
      jobTitle: 'CVP Operations',
      mail: 'NestorW@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '36/2121',
      preferredLanguage: 'en-US',
      surname: 'Wilke',
      userPrincipalName: 'NestorW@M365x214355.onmicrosoft.com',
      id: '089a6bb8-e8cb-492c-aa41-c078aa0b5120',
    },
    {
      businessPhones: ['+1 502 555 0144'],
      displayName: 'Patti Fernandez',
      givenName: 'Patti',
      jobTitle: 'President',
      mail: 'PattiF@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '15/1102',
      preferredLanguage: 'en-US',
      surname: 'Fernandez',
      userPrincipalName: 'PattiF@M365x214355.onmicrosoft.com',
      id: '40079818-3808-4585-903b-02605f061225',
    },
    {
      businessPhones: ['+20 255501070'],
      displayName: 'Pradeep Gupta',
      givenName: 'Pradeep',
      jobTitle: 'Accountant II',
      mail: 'PradeepG@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: '98/2202',
      preferredLanguage: 'en-US',
      surname: 'Gupta',
      userPrincipalName: 'PradeepG@M365x214355.onmicrosoft.com',
      id: 'ec63c778-24e1-4240-bea3-d12a167d5232',
    },
    {
      businessPhones: [],
      displayName: 'Conf Room Rainier',
      givenName: null,
      jobTitle: null,
      mail: 'Rainier@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Rainier@M365x214355.onmicrosoft.com',
      id: '6f1c452b-f9f4-4f43-8c42-17e30ab0077c',
    },
    {
      businessPhones: [],
      displayName: 'Conf Room Stevens',
      givenName: null,
      jobTitle: null,
      mail: 'Stevens@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'Stevens@M365x214355.onmicrosoft.com',
      id: '5c7188eb-da70-4f1a-a8a5-afc26c2fe22c',
    },
    {
      businessPhones: [],
      displayName: 'Support shared mailbox',
      givenName: null,
      jobTitle: null,
      mail: 'support@M365x214355.onmicrosoft.com',
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: null,
      surname: null,
      userPrincipalName: 'support@M365x214355.onmicrosoft.com',
      id: 'c4e9da8e-d5d1-4781-b945-bbe1eb906970',
    },
  ],
};
