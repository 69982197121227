import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

import { createBooking, updateBooking } from '@src/api/booking';
import { findMaxSizeRoom, findRoom, getLocations } from '@src/api/location';
import { getFloorNumber } from '@src/api/rooms';
import { GlobalOrgData } from '@src/contexts/GlobalUIContext';
import { conferenceCities, mediaTypes, roomSizes, time } from '@src/lib/constants';
import { convertTo12HourFormat, createTimeSlots } from '@src/lib/helper';
import { RoomsType } from '@src/Modals/Rooms';

import { SelectedValues } from './type';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const useHomeLogic = () => {
  const navigate = useNavigate();
  const reschedule = useSearchParams()[0].get('reschedule');

  const initialSelectedValue = {
    selectedDate: '',
    location: '',
    type: '',
    floorNumber: '',
    capacity: '',
    mediaSupport: '',
    startTimeTemp: '',
    startTime: '',
    endTimeTemp: '',
    endTime: '',
  };

  const [selectedValues, setSelectedValues] = useState<SelectedValues>(initialSelectedValue);

  const [locations, setLocations] = useState<
    { id: string; name: string; timeZone: { label: string; value: string } }[]
  >([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomsType[] | undefined>();
  const [multiLocationRooms, setMultiLocationRoom] = useState<RoomsType[] | undefined>();
  const [room, setRoom] = useState<Record<string, string>[]>();
  const [selectedToggle, setSelectedToggle] = useState('selectedDate');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDatePref, setSelectedDatePref] = useState('');
  const [isDirtyServices, setIsDirtyServices] = useState(false);

  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#05b6d8',
    },
  ]);

  const { organisation, loading } = useContext(GlobalOrgData);
  const location = useLocation();

  const [selectSeprateLocation, setSelectSeprateLocation] = useState<{
    location?: string;
    floorNumber?: string;
    mediaSupport?: string;
    room?: string;
  }>();

  const [selectedServices, setSelectedServices] =
    useState<{ name: string; categories?: string[]; specialInstructions?: string }[]>();

  const [locationSelectionType, setLocationSelectionType] = useState<'allLocations' | 'multiple' | ''>('');

  const [floorsOption, setFloorsOption] = useState<{
    floorDetails: { floorNumber: number; floorMap?: string }[];
    floorNotFound: boolean;
  }>();

  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line complexity
  const handleBookingSubmit = async ({ userId, alternativeName }: { userId: string; alternativeName?: string }) => {
    try {
      const bookingDate = getBookingDate(selectedValues.selectedDate) || '';
      const bookingEndDate = getBookingDate(dateRange[0]?.endDate?.toString() || '') || '';
      const isSaperateLocation = locationSelectionType === 'allLocations' && room?.length;

      if (!userId) {
        console.log('Error: User ID is required');
        return;
      }

      const commonBookingData = {
        // room: room?.roomId || '',
        bookingEndDate,
        bookingDate,
        endTime: selectedValues?.endTime || '',
        startTime: selectedValues?.startTime || '',
        user: userId,
        location: selectedValues?.location?.toString() || '',
        status: 'Confirmed',
        ...(alternativeName ? { alternativeName } : {}),
      };

      if (reschedule) {
        await updateBooking(reschedule, commonBookingData, organisation?.id || '');
        navigate(`/${location.pathname.split('/')[1]}/booking`);
      } else {
        const bookingRequests = [];

        if (!locationSelectionType || isSaperateLocation) {
          for (const roomData of room || []) {
            bookingRequests.push({
              ...commonBookingData,
              room: roomData.roomId,
              services: selectedServices,
              location: isSaperateLocation ? selectSeprateLocation?.location || '' : commonBookingData.location || '',
            });
          }
        }

        if (locationSelectionType) {
          for (const roomData of multiLocationRooms || []) {
            bookingRequests.push({
              ...commonBookingData,
              room: roomData?._id || '',
              location: roomData?.location,
              services: selectedServices,
            });
          }
        }

        await Promise.all(
          bookingRequests.map(bookingData => createBooking({ ...bookingData, orgID: organisation?.id || '' }))
        );
        navigate(`/${location.pathname.split('/')[1]}/booking`);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const getEmptyKey = useCallback(
    (data: SelectedValues) => {
      const valueKey = Object.keys(data).find(emptyValueKey => {
        return !data[emptyValueKey];
      });

      if (!valueKey && data.type !== 'hotel' && !selectedServices?.length) {
        return 'services';
      }

      return valueKey;
    },
    [selectedServices?.length]
  );

  const handleSelect = useCallback(
    // eslint-disable-next-line complexity
    (key: string, value: string) => {
      const isSeprateLocation = selectSeprateLocation?.location && key === 'floorNumber';

      const newSelectedValues: SelectedValues = isSeprateLocation
        ? { ...selectedValues }
        : { ...selectedValues, [key]: value };

      if (key === 'location') {
        newSelectedValues.location = value;

        if (!locationSelectionType) {
          newSelectedValues.floorNumber = '';
        }
      }

      if (key === 'type' && value === 'hotel') {
        newSelectedValues.capacity = newSelectedValues.capacity || '1 person';
        newSelectedValues.floorNumber = newSelectedValues.floorNumber || '0';
        newSelectedValues.mediaSupport = 'none';
        setSelectedServices([]);
      }

      if (key === 'capacity' && newSelectedValues?.type !== 'hotel') {
        newSelectedValues.floorNumber = newSelectedValues.floorNumber || '0';
      }

      if (isSeprateLocation) {
        setSelectSeprateLocation(prev => ({ ...prev, floorNumber: value }));
      }

      const valueKey = getEmptyKey(newSelectedValues) || '';
      setSelectedValues(newSelectedValues);

      const handleToggle = (toggleKey: string) => setSelectedToggle(toggleKey || valueKey || '');

      switch (key) {
        case 'startTimeTemp':
        case 'endTimeTemp':
          handleToggle(key.slice(0, -4));
          break;

        case 'location':
          handleToggle('type');
          break;

        case 'type':
          if (value === 'hotel') {
            handleToggle(valueKey);
          } else {
            handleToggle('capacity');
          }

          break;

        case 'capacity':
          if (selectedValues?.type !== 'hotel') {
            setIsDirtyServices(!!selectedServices?.length);

            if (selectedValues?.mediaSupport && !selectedServices?.length) {
              handleToggle('services');
            } else if ((floorsOption?.floorDetails?.length || 0) > 1) {
              handleToggle('floorNumber');
            } else {
              handleToggle(valueKey);
            }
          }

          break;

        case 'mediaSupport':
          handleToggle(selectedValues?.type === 'hotel' ? valueKey : 'services');
          break;

        default:
          handleToggle(valueKey);
          break;
      }

      if (room) {
        setIsOpen(false);
        setRoom(undefined);
      }
    },
    [
      floorsOption?.floorDetails?.length,
      getEmptyKey,
      locationSelectionType,
      room,
      selectSeprateLocation?.location,
      selectedServices?.length,
      selectedValues,
    ]
  );

  const fetchData = useCallback(async () => {
    try {
      const options = { limit: 30 };
      const responseLocation = await getLocations({ ...options, orgID: organisation?.id || '' });
      setLocations(responseLocation?.results || []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
    }
  }, [organisation?.id]);

  const getValue = (key: string, value: string) => {
    switch (key) {
      case 'selectedDate':
      case 'selectedEndDate':
        if (selectedDatePref === 'multipleDays') {
          return `${format(new Date(dateRange[0].startDate || ''), 'EEE MM/dd')} ${format(
            new Date(dateRange[0].endDate || ''),
            'EEE MM/dd'
          )}`;
        } else {
          return dayjs(value).format('MMMM D');
        }
      case 'location':
        if (locationSelectionType) {
          return locationSelectionType === 'multiple' ? 'Multiple Location' : 'All Locations';
        }

        return locations?.find(({ id }) => id === value)?.name;
      case 'endTime':
        return value ? convertTo12HourFormat(value) : '';
      case 'startTime':
        return value ? convertTo12HourFormat(value) : '';
      default:
        return value || '';
    }
  };

  const fetchFloors = async () => {
    const locationID = selectSeprateLocation?.location || selectedValues.location.toString();

    if (!locationID) return;

    if (locationSelectionType && !selectSeprateLocation?.location) return;

    const responseFloor = await getFloorNumber(locationID, organisation?.id || '');

    setFloorsOption(responseFloor || []);
  };

  const getBookingDate = (date: string) => {
    return dayjs(date)?.format('YYYY-MM-DD');
  };

  useEffect(() => {
    if (organisation?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  useEffect(() => {
    fetchFloors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.location, locationSelectionType, selectSeprateLocation?.location]);

  useEffect(() => {
    const {
      location: roomLocation,
      type,
      capacity,
      mediaSupport,
      startTime,
      endTime,
      floorNumber,
      selectedDate,
    } = selectedValues;

    // eslint-disable-next-line complexity
    const handleFindRoom = async () => {
      if (locationSelectionType) {
        try {
          const res = await findMaxSizeRoom({
            location: roomLocation.toString(),
            type,
            capacity: '1 person',
            mediaSupport,
            startTime,
            endTime,
            floorNumber: +floorNumber,
            bookingDate: getBookingDate(selectedValues.selectedDate) || '',
            orgID: organisation?.id || '',
            ...(selectedDatePref === 'multipleDays'
              ? { bookingEndDate: getBookingDate(dateRange[0]?.endDate?.toString() || '') }
              : {}),
          });

          setMultiLocationRoom(res?.rooms || []);
          setErrorMessage('');

          if (locationSelectionType === 'multiple') {
            setSelectedToggle('selectRoom');
            return;
          }
        } catch (err) {
          console.log('error', err);
        }
      }

      if (!locationSelectionType || (locationSelectionType === 'allLocations' && selectSeprateLocation?.location)) {
        findRoom({
          location: selectSeprateLocation?.location || roomLocation.toString(),
          type,
          capacity: locationSelectionType === 'allLocations' ? '1 person' : capacity,
          mediaSupport,
          startTime,
          endTime,
          floorNumber: Number(selectSeprateLocation?.floorNumber) || +floorNumber || 0,
          bookingDate: getBookingDate(selectedValues.selectedDate) || '',
          ...(selectedDatePref === 'multipleDays'
            ? { bookingEndDate: getBookingDate(dateRange[0]?.endDate?.toString() || '') }
            : {}),
          orgID: organisation?.id || '',
        })
          .then(res => {
            setRooms(res);
            setErrorMessage('');
          })
          .catch(err => {
            setRooms([]);
          });
      }
    };

    if (roomLocation && type && capacity && mediaSupport && startTime && endTime && floorNumber && selectedDate) {
      handleFindRoom();
    } else {
      setRooms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (reschedule) {
      const storedValues = localStorage.getItem('seletedValues');
      const parsedValues = storedValues ? JSON.parse(storedValues) : null;

      const serviceValue = localStorage.getItem('serviceValue');

      if (serviceValue) {
        setSelectedServices(JSON.parse(serviceValue));
      }

      if (parsedValues) {
        setSelectedValues(parsedValues as SelectedValues);

        const valueKey = Object.keys(parsedValues).find(emptyValueKey => {
          return !parsedValues[emptyValueKey];
        });

        setSelectedToggle(valueKey || '');
      }
    }
  }, [reschedule]);

  const chipComponentsConfig = [
    {
      condition: selectedValues.location && selectedToggle === 'floorNumber',
      floors: [{ floorNumber: 0 }, ...(floorsOption?.floorDetails || [])],
      keyName: 'floorNumber',
      heading: 'Floor Number',
      columnNumber: 2,
      floorNotFound: floorsOption?.floorNotFound,
    },
    {
      condition: (selectedValues.location || floorsOption?.floorNotFound) && selectedToggle === 'type',
      values: conferenceCities,
      keyName: 'type',
      heading: 'Room Type',
      columnNumber: 2,
    },
    {
      condition: selectedValues.type && selectedToggle === 'capacity',
      values: roomSizes,
      keyName: 'capacity',
      heading: 'Room Size',
    },
    {
      condition: selectedValues.capacity && selectedToggle === 'mediaSupport',
      values: mediaTypes,
      keyName: 'mediaSupport',
      heading: 'Media',
      columnNumber: 2,
    },
    {
      condition: selectedValues.mediaSupport && selectedToggle === 'startTimeTemp',
      values: time,
      keyName: 'startTimeTemp',
      heading: 'Start Time',
    },
    {
      condition: selectedValues.startTime && selectedToggle === 'endTimeTemp',
      values: time,
      keyName: 'endTimeTemp',
      heading: 'End Time',
    },
    selectedValues.startTimeTemp
      ? {
          condition: selectedValues.startTimeTemp && selectedToggle === 'startTime',
          values: createTimeSlots(selectedValues.startTimeTemp),
          keyName: 'startTime',
          heading: 'Select Start Time',
        }
      : {},

    selectedValues.endTimeTemp
      ? {
          condition: selectedValues.endTimeTemp && selectedToggle === 'endTime',
          values: createTimeSlots(selectedValues.endTimeTemp),
          keyName: 'endTime',
          heading: 'Select End Time',
        }
      : {},
  ];

  return {
    selectedValues,
    handleBookingSubmit,
    handleSelect,
    getValue,
    rooms,
    isOpen,
    setIsOpen,
    selectedToggle,
    chipComponentsConfig,
    selectedServices,
    setSelectedServices,
    isDirtyServices,
    setIsDirtyServices,
    containerRef,
    floorsOption,
    locations,
    setSelectedValues,
    initialSelectedValue,
    setSelectedToggle,
    room,
    setRoom,
    locationSelectionType,
    setLocationSelectionType,
    getEmptyKey,
    errorMessage,
    selectSeprateLocation,
    setSelectSeprateLocation,
    selectedDatePref,
    setSelectedDatePref,
    organisation,
    loading,
    dateRange,
    setDateRange,
  };
};
