import { CSSProperties, Dispatch, SetStateAction } from 'react';

import { Button, useMediaQuery } from '@mui/material';

import { GridContainer, Heading, StyledChip } from './style';
import { SelectedValues } from './type';

export const LocationsChips = ({
  locationSelectionType,
  selectedValues,
  handleMultiLocationSelect,
  locations = [],
  handleSelect,
  setSelectedValues,
  setLocationSelectionType,
  selectSeprateLocation,
  setSelectSeprateLocation,
  setSelectedToggle,
}: {
  locationSelectionType: '' | 'multiple' | 'allLocations';
  selectedValues: SelectedValues;
  handleMultiLocationSelect: (isAllLocation?: boolean) => void;
  locations: {
    id: string;
    name: string;
    timeZone: {
      label: string;
      value: string;
    };
  }[];
  setSelectSeprateLocation: Dispatch<
    SetStateAction<
      | {
          location?: string;
          floorNumber?: string;
          mediaSupport?: string;
          room?: string;
        }
      | undefined
    >
  >;
  selectSeprateLocation:
    | {
        location?: string;
        floorNumber?: string;
        mediaSupport?: string;
        room?: string;
      }
    | undefined;
  setLocationSelectionType: (value: SetStateAction<'' | 'multiple' | 'allLocations'>) => void;
  handleSelect: (key: string, value: string) => void;
  setSelectedToggle: (value: SetStateAction<string>) => void;
  setSelectedValues: (value: SetStateAction<SelectedValues>) => void;
}) => {
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const handleSeparateBookingLocation = ({ locationId }: { locationId: string }) => {
    setSelectSeprateLocation(prev => ({
      ...prev,
      location: locationId,
    }));
  };

  const getChipColor = (id: string) => {
    if (!locationSelectionType || locationSelectionType === 'multiple') {
      return selectedValues.location?.includes(id) ? { color: 'white', background: '#05b6d8' } : {};
    }

    if (locationSelectionType === 'allLocations') {
      if (selectedValues.location?.includes(id)) {
        return selectSeprateLocation?.location === id
          ? { color: 'black', background: 'yellow' }
          : { color: 'black', background: 'rgb(222, 255, 251)' };
      }
    }

    return {};
  };

  const renderLocationChip = (id: string, name: string) => {
    return (
      <StyledChip
        key={id}
        style={getChipColor(id)}
        onClick={() => {
          if (locationSelectionType === 'allLocations') {
            handleSeparateBookingLocation({ locationId: id });
          } else if (!locationSelectionType) {
            handleSelect('location', id);
          } else if (locationSelectionType === 'multiple') {
            setSelectedValues(prev => {
              const currentLocations = Array.isArray(prev.location) ? prev.location : [];
              const locationSet = new Set(currentLocations);

              locationSet.has(id) ? locationSet.delete(id) : locationSet.add(id);
              return { ...prev, location: Array.from(locationSet) };
            });
          }
        }}
      >
        {name}
      </StyledChip>
    );
  };

  const commonChipStyles: CSSProperties = {
    border: '1px solid #05b6d8',
    borderRadius: '7px',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <GridContainer $columnNumber={2}>
      <Heading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: locationSelectionType ? 'space-between' : 'center',
          userSelect: 'none',
          textTransform: 'uppercase',
        }}
      >
        Location
        {locationSelectionType && (
          <Button
            color='success'
            variant='contained'
            disabled={selectedValues?.location?.length <= 1}
            onClick={() => {
              handleMultiLocationSelect(locationSelectionType === 'allLocations');
            }}
          >
            Done
          </Button>
        )}
      </Heading>

      {locations.length > 0 && locations.map(({ id, name }) => renderLocationChip(id, name))}

      <div style={{ ...commonChipStyles, height: locationSelectionType === 'multiple' ? 'auto' : 'fit-content' }}>
        <StyledChip
          style={{ border: '1px solid #05b6d8' }}
          $isSelected={locationSelectionType === 'multiple'}
          onClick={() => {
            setSelectedValues(prev => ({ ...prev, location: [] }));
            setSelectSeprateLocation({});
            setLocationSelectionType(prev => (prev !== 'multiple' ? 'multiple' : ''));
          }}
        >
          MULTIPLE LOCATIONS
        </StyledChip>
        {locationSelectionType === 'multiple' && (
          <div
            style={{
              padding: '2px 5px',
              fontSize: isDesktop ? '18px' : '12px',
              marginTop: '4px',
              fontWeight: 700,
              color: 'black',
              textAlign: 'justify',
            }}
          >
            Please select two (2) or more locations and click 'Done'
          </div>
        )}
      </div>

      <div style={{ ...commonChipStyles, height: locationSelectionType === 'allLocations' ? 'auto' : 'fit-content' }}>
        <StyledChip
          style={{ height: 'fit-content', border: '1px solid #05b6d8' }}
          onClick={() => {
            if (locationSelectionType === 'allLocations') {
              setLocationSelectionType('');
              setSelectedValues(prev => ({ ...prev, location: [] }));
              setSelectSeprateLocation({});
            } else {
              setSelectedValues(prev => ({ ...prev, location: locations?.map(({ id }) => id) || [] }));
              setLocationSelectionType('allLocations');
            }
          }}
          $isSelected={locationSelectionType === 'allLocations'}
        >
          All Locations
        </StyledChip>
        {locationSelectionType === 'allLocations' && (
          <div
            style={{
              padding: '2px 5px',
              fontSize: isDesktop ? '18px' : '12px',
              marginTop: '4px',
              fontWeight: 700,
              color: 'black',
              textAlign: 'justify',
            }}
          >
            Default will be the largest capacity room per location Select any location you want to customize or change
            click 'DONE'
          </div>
        )}
      </div>
    </GridContainer>
  );
};
